export const environment = {
  baseUrl: '/',
  articleApiUrl: 'https://api-comp.azurewebsites.net',
  imageUrl: 'https://cbre-images-api-dev.azurewebsites.net',
  indexingApiUrl: 'https://api-index-dev.azurewebsites.net',
  ondemandApiUrl: 'https://api-ondemand-dev.azurewebsites.net',
  educationApiUrl: 'https://educationapi-dev.azurewebsites.net',
  eriApiUrl: 'https://api-eri-dev.azurewebsites.net',
  blsApiUrl: 'https://api-bls-dev.azurewebsites.net',
  adminUrl: 'https://api-admin-development.azurewebsites.net',
  esriSuggestApiUrl: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest',
  competitionApiUrl: 'https://api-comp.azurewebsites.net',
  laborplanApiUrl: 'https://cbre-laborplan-api-dev.azurewebsites.net',
  production: false,
  securityTenantName: 'cbre.onmicrosoft.com',
  securityClientId: '1d2914a7-ba45-4de1-b417-ee576e4d7b76',
  isResearch: true,
  isInterviews: true,
  INTERVIEW_API_URL: 'https://api-comp.azurewebsites.net',
  OKTA_ISSUER_URL: 'https://login-uat.cbre.com/oauth2/default',
  OKTA_CLIENT_ID: '0oa1w0yb2polBPRjT0h8',
  THOUGHTSPOT_USERNAME: 'US_SVC_LA_TS_SF_INT',
  ANALYTICS_KEY: 'e3F324y30cELUJsJcrIdz2sWP2o1LUfD',
  GLV_HOME: 'http://glv-dev.cbre.com/Home',
  puppeteerApiUrl: 'https://lag-node-functionapp-dev.use2-cbre-nonprod.appserviceenvironment.net/api/html-screenshot?code=fvBFJS7LcHQsCyg7apajF2AVtXZJLeWGlur2YhDGCQ1cAzFuhMPr-A==',
  geoskillFaqUrl: 'https://cbre.sharepoint.com/sites/intra-AdvisoryTransactionServices/SiteAssets/Forms/AllItems.aspx?id=%2Fsites%2Fintra%2DAdvisoryTransactionServices%2FSiteAssets%2FSitePages%2FAMS%2DConsulting%2FGeoSkill%5FFAQ%2Epdf&parent=%2Fsites%2Fintra%2DAdvisoryTransactionServices%2FSiteAssets%2FSitePages%2FAMS%2DConsulting',
  cartoIframeUrl: 'https://cbre-lbr-nlytcs-stg.carto.solutions/',
  ANALYTICS_UUID_API: 'https://mktgservice.cbre.com/v2/uuid-generator',
  cartoProfilesMigrationUrl: 'https://us-east1-carto-ps-cbre-lbr-nlytcs-dev.cloudfunctions.net/profiles-migration'
};
