import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoggingService } from 'src/app/core/services/logging.service';
import { UserDataService } from 'src/app/core/services/userData.service';
import { ProfileDetails } from 'src/app/models/profileDetails.model';
import { TokenService } from 'src/app/services/token.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private url = environment.adminUrl;

  constructor(
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private userDataService: UserDataService,
    private tokenService: TokenService
  ) {}

  getProfileMetaData(country?): Observable<any> {
    const apiUrl = this.url + '/profile/indicators';

    return this.httpClient.post(apiUrl, country).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }

  cartoProfilesMigration(): Observable<any> {
      const apiUrl = environment.cartoProfilesMigrationUrl;
      const accessToken = this.tokenService.getAccessToken();
      const header = {
            headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + accessToken),
          };

      return this.httpClient.post(apiUrl, {}, header).pipe(
        map((res) => res),
        catchError(this.handleError),
      );
    }

  saveProfile(
    profileId: any,
    profileJson: any,
    name: string,
    internalName: string,
    isFavorite: boolean,
    country: string[],
    isActive: boolean,
    linkToBenchmark: boolean,
    linkToMultiSite: boolean,
    linkToGeoskill: boolean,
    clientIds: number[] 
  ): Observable<any> {
    const apiUrl = this.url + '/profile';

    const postModel = {
      profileName: name,
      internalNotes: internalName,
      profileDatapoints: profileJson,
      defaultProfile: isFavorite,
      visibleInBenchmark: linkToBenchmark,
      visibleInMultiSite: linkToMultiSite,
      visibleInGeoskill: linkToGeoskill,
      active: isActive,
      countryCodes: country,
      clientIds: clientIds
    };

    if (profileId == null) {
      return this.httpClient.post(apiUrl, postModel).pipe(
        map((res) => res),
        catchError(this.handleError),
      );
    } else {
      return this.httpClient.put(apiUrl + '/' + profileId, postModel).pipe(
        map((res) => res),
        catchError(this.handleError),
      );
    }
  }

  updateProfile(
    profileJson: any,
    name: string,
    internalName: string,
    isFavorite: boolean,
    country: string,
    isActive: boolean,
    linkToBenchmark: boolean,
    linkToMultiSite: boolean,
    linkToGeoskill: boolean,
  ): Observable<any> {
    const apiUrl = this.url + '/profile';

    const postModel = {
      profileName: name,
      internalNotes: internalName,
      profileDatapoints: profileJson,
      lastModifiedBy: this.userDataService.userInfo.email,
      defaultProfile: isFavorite,
      profileType: 'Regular',
      countryAbbreviation: country,
      visibleInBenchmark: linkToBenchmark,
      visibleInMultiSite: linkToMultiSite,
      visibleInGeoskill: linkToGeoskill,
      active: isActive,
    };

    return this.httpClient.put(apiUrl, postModel).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }

  getProfileDetails(id: string): Observable<ProfileDetails> {
    const apiUrl = this.url + '/profile?id=' + id;

    return this.httpClient.get(apiUrl).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }

  getAllProfiles(countryCode?: string): Observable<any> {
    const apiUrl = countryCode 
    ? `${this.url}/profiles?countryAbbr=${countryCode}`
    : `${this.url}/profiles`

    return this.httpClient.get(apiUrl);
  }

  updateFavorite(favorite: boolean, profileIds: any[]) {
    const apiUrl = this.url + '/profiles/favorite?isFavorite=' + favorite;

    return this.httpClient.put(apiUrl, profileIds).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }

  updateStatus(status: boolean, profileIds: any[]) {
    const apiUrl = this.url + '/profiles/disable?inActivate=' + status;

    return this.httpClient.put(apiUrl, profileIds).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }

  deleteProfiles(profileIds: any[]) {
    const apiUrl = this.url + '/profiles/delete';

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: profileIds,
    };

    return this.httpClient.delete(apiUrl, httpOptions).pipe(
      map((res) => res),
      catchError(this.handleError),
    );
  }

  handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
