import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SpinnerService } from 'src/app/core/spinner.service';

import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class AllProfilesResolver implements Resolve<any> {
  constructor(private profileService: ProfileService, private spinnerService: SpinnerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.spinnerService.show(' Loading Profiles. Please wait..');
    return this.profileService.getAllProfiles().pipe(
      catchError((error) => {
        console.log('error fetching all profiles ' + error);
        return empty();
      }),
    );
  }
}
